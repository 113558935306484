import React, { lazy, Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import ThemeToggle from './components/ThemeToggle';
import PrivateRoute from './components/PrivateRoute';
import Login from './pages/Login';
import Home from './pages/Home';
const ComingSoon = lazy(() => import('./pages/ComingSoon'));

function App() {
  return (
    <>
      <Suspense fallback={<div className="min-h-screen flex items-center justify-center">
        <div className="w-16 h-16 border-4 border-indigo-500 border-t-transparent rounded-full animate-spin"></div>
      </div>}>
        <Routes>
          <Route path="/" element={<ComingSoon />} />
          {/*<Route path="/" element={<Home />} />
          <Route path="/user/login" element={<Login />} />
          <Route
            path="/user"
            element={
              <PrivateRoute>
                <UserDashboard />
              </PrivateRoute>
            }
          />
          */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Suspense>
      <ThemeToggle />
    </>
  );
}

export default App;