export const API_CONFIG = {
  BASE_URL: import.meta.env.VITE_BACKEND_URL || "http://localhost:3000",
  ENDPOINTS: {
    AUTH: {
      LOGIN: "/api/users/login",
      CHECK: "/api/users/check",
      REGISTER: "/api/users/register",
      UPDATE_PASSWORD: "/api/users/update-password",
      UPDATE_USER_ROLE: "/api/users/update-user-role",
      UPDATE_ROLE: "/api/users/update-role",
      GET_USERS: "/api/users/users",
      GET_ROLES: "/api/users/roles",
      DELETE_USER: "/api/users/delete",
      DELETE_ROLE: "/api/users/delete-role",
      CREATE_ROLE: "/api/users/create-role"
    },
    CONTEST: {
      CONTESTANTS: {
        GET_ALL: "/api/contest/contestant",
        GET_TODAY: "/api/contest/contestant/today",
        CREATE: "/api/contest/contestant",
        UPDATE: "/api/contest/update-contestant",
        DELETE: "/api/contest/contestant"
      },
      TYPES: {
        GET_ALL: "/api/contest/contestants-type",
        CREATE: "/api/contest/contestants-type",
        UPDATE: "/api/contest/update-contestants-type",
        DELETE: "/api/contest/contestants-type"
      },
      DAYS: {
        GET_ALL: "/api/contest/classification-day",
        CREATE: "/api/contest/classification-day",
        UPDATE: "/api/contest/update-classification-day",
        DELETE: "/api/contest/classification-day"
      },
      STATUS: {
        GET: "/api/contest/contest-status",
        UPDATE: "/api/contest/contest-status",
        DELETE: "/api/contest/contest-status"
      },
      ENTRIES: {
        GET_ALL: "/api/entry/entries",
        CREATE: "/api/entry/entries",
        DELETE: "/api/entry/entries",
        VALIDATE: "/api/entry/entries/validate",
        PREFIX: "/api/entry/by-prefix",
        UNVALIDATED: "/api/entry/unvalidated",
        VALIDATE_ALL: '/api/entry/entries/validate-all',
        GET_RANGE : '/api/entry/range'
      }
    },
    VOTE: {
      CREATE: "/api/vote/votes",
      RESULTS: "/api/vote/results",
      RESULTS_LIVE: "/api/vote/live",
      DAILY_STATUS: "/api/vote/daily-stats",
      ACCESS_CONTROL: "/api/vote/vote-access"
    },
    CLOUDFRONT: {
      CLEAR_CACHE: '/api/cloudfront/clear-cache'
    },
    BACKUP: {
      LIST: '/api/backup/list',
      CREATE: '/api/backup/create',
      RESTORE: '/api/backup/restore',
      RESET: '/api/backup/reset-db',

    },
    CATEGORY: {
      GET_ALL: "/api/category/categories",
      GET_TODAY: "/api/category/categories/today",
      CREATE: "/api/category/categories",
      DELETE: "/api/category/categories"
    }
  }
};